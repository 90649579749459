import { FC } from 'react';
import { Space } from 'antd';

import { useLayoutDisplay } from '../../hooks';
import { Feature } from './feature.component';
import { PictureSvg, VideoSvg } from './feature-svg.component';
import { VoiceSvg } from '../common-svg.component';

import './features.component.css';

export const Features: FC<{
  textMessagePrice?: number;
  voiceMessagePrice?: number;
  style?: React.CSSProperties;
}> = ({ textMessagePrice, voiceMessagePrice, style }) => {
  const { isMobile } = useLayoutDisplay();

  return (
    <Space
      direction="horizontal"
      size={8}
      className={'features-container' + (isMobile ? ' mobile' : '')}
      wrap={!isMobile}
      style={style}
    >
      <Feature
        icon={VoiceSvg}
        label="Voice Messages"
        price={
          voiceMessagePrice ? '$' + voiceMessagePrice?.toFixed(2) : undefined
        }
      />

      <Feature
        icon={PictureSvg}
        label="Pictures"
        price={textMessagePrice ? 'PPV' : undefined}
      />

      <Feature
        icon={VideoSvg}
        label="Videos"
        price={textMessagePrice ? 'PPV' : undefined}
      />
    </Space>
  );
};
