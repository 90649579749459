import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import './navigation-button.component.css';

export const NavigationButton: FC<
  PropsWithChildren<{
    label?: string;
    active?: boolean;
    large?: boolean;
    to?: string;
    target?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
  }>
> = ({ children, label, active, large, to, target, onClick, style }) => {
  const classNames = ['navigation-button'];

  if (active) classNames.push('active');
  if (large) classNames.push('large');

  if (to) {
    return (
      <Link
        className={classNames.join(' ')}
        style={style}
        to={to}
        target={target}
        onClick={onClick}
      >
        <div className="icon-circle">{children}</div>

        {label && <div style={{ marginLeft: '10px' }}>{label}</div>}
      </Link>
    );
  }

  return (
    <div
      className={classNames.join(' ')}
      style={style}
      onClick={active ? undefined : onClick}
    >
      <div className="icon-circle">{children}</div>

      {label && <div style={{ marginLeft: '10px' }}>{label}</div>}
    </div>
  );
};
