import { Space } from 'antd';
import Icon from '@ant-design/icons';

import { useMiscStore } from '../../../stores';
import { ClassicModal } from '../classic-modal/classic-modal.component';
import { CloseSvg, SubscriptionSvg } from '../common-svg.component';
import { WideButton } from '../wide-button/wide-button.component';
import SubscriptionBenefit from '../../../routes/app/subscription/subscription-benefit.component';

import './subscription-modal.component.css';

export const SubscriptionModal: React.FC = () => {
  const { subscriptionModalOpen, setSubscriptionModalOpen } = useMiscStore();

  return (
    <ClassicModal
      isOpen={subscriptionModalOpen}
      close={() => setSubscriptionModalOpen(false)}
      width="80%"
      maxWidth="400px"
    >
      <div
        className="button-circle"
        onClick={() => setSubscriptionModalOpen(false)}
      >
        <Icon component={CloseSvg} />
      </div>

      <div className="subscription-modal-container">
        <div
          style={{
            textAlign: 'center',
            fontSize: '28px',
            color: 'var(--white)',
            marginBottom: '20px',
          }}
        >
          Upgrade to Unlock
          <br />
          <span style={{ fontWeight: 'bold' }}>Premium Features!</span>
        </div>

        <div
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            color: 'var(--grey6)',
          }}
        >
          With subscription you get access to:
        </div>

        <Space
          direction="vertical"
          size="middle"
          style={{ marginBottom: '40px' }}
        >
          <SubscriptionBenefit label="Unlimited text messages" />
          <SubscriptionBenefit label="Send & Listen to voice messages" />
          <SubscriptionBenefit label="Pictures & Videos" />
          <SubscriptionBenefit label="Get 100 FREE credits / month" />
          <SubscriptionBenefit label="and more..." />
        </Space>

        <WideButton
          label="Upgrade to Premium"
          accent
          to="/subscription"
          onClick={() => setSubscriptionModalOpen(false)}
        >
          <Icon component={SubscriptionSvg} style={{ width: '18px' }} />
        </WideButton>
      </div>
    </ClassicModal>
  );
};
