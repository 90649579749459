import { Drawer, Space } from 'antd';
import Icon from '@ant-design/icons';
import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CreditsDrawerTitle from './credits-drawer-title.component';
import {
  useAPIRequest,
  CreditBundlesRequest,
  CreditsCheckoutRequest,
  CreditBundle,
} from '../../../api';
import { useChatStore, useInitStore, useMiscStore } from '../../../stores';
import { useLayoutDisplay } from '../../hooks';
import { Loader } from '../loader';
import { CloseSvg } from '../common-svg.component';

import './credits-drawer.component.css';

export const CreditsDrawer: FC = () => {
  const location = useLocation();

  const user = useInitStore((state) => state.user);
  const character = useChatStore((state) => state.character);

  const {
    creditsDrawerInsufficientCredits,
    creditsDrawerOpen,
    setCreditsDrawerOpen,
  } = useMiscStore();

  const { isMobile } = useLayoutDisplay();

  const [selected, setSelected] = useState<CreditBundle>();

  const { response: bundles, isLoading: isLoadingBundles } = useAPIRequest<{
    data: CreditBundle[];
  }>(CreditBundlesRequest);

  const { request } = useAPIRequest<{
    url: string;
  }>(CreditsCheckoutRequest, {
    immediate: false,
    onSuccess: ({ url }) => {
      if (url) window.location.href = url;
    },
  });

  const confirm = () => {
    if (!selected) return;

    request({
      characterId: character?.id,
      priceId: selected?.id,
      route: location.pathname,
    });
  };

  let height: number | undefined = undefined;

  if (isMobile) height = creditsDrawerInsufficientCredits ? 437 : 393;

  // height = (height || 0) + 50;

  return (
    <Drawer
      open={creditsDrawerOpen}
      placement={isMobile ? 'bottom' : 'right'}
      closeIcon={null}
      onClose={() => setCreditsDrawerOpen(false)}
      height={height}
    >
      <div className="credits-drawer-container">
        <div
          className="button-circle"
          onClick={() => setCreditsDrawerOpen(false)}
        >
          <Icon component={CloseSvg} />
        </div>

        <CreditsDrawerTitle
          insufficientCredits={!!creditsDrawerInsufficientCredits}
          style={{ marginBottom: '28px' }}
        />

        <Space
          direction="vertical"
          align="center"
          size={4}
          style={{ width: '100%', marginBottom: '24px' }}
        >
          <div className="credits-value">{user?.credits}</div>

          <div className="credits-title">Credits</div>
        </Space>

        <Loader isLoading={isLoadingBundles}>
          <Space
            direction="horizontal"
            align="center"
            size="middle"
            className={'bundles-container' + (isMobile ? ' mobile' : '')}
            wrap={!isMobile}
          >
            {bundles?.data.map((bundle: CreditBundle) => {
              return (
                <div
                  className={
                    'bundle' + (bundle.id === selected?.id ? ' active' : '')
                  }
                  style={{ position: 'relative' }}
                  key={bundle.id}
                  onClick={() => setSelected(bundle)}
                >
                  <Space
                    direction="horizontal"
                    style={{ marginBottom: '20px' }}
                  >
                    <img src="/flame.png" style={{ width: '16px' }} />

                    <div style={{ fontWeight: 'bold' }}>{bundle.credits}</div>
                  </Space>

                  <div
                    style={{
                      fontSize: '14px',
                      position: 'absolute',
                      bottom: '20px',
                    }}
                  >
                    ${bundle.price}
                  </div>
                </div>
              );
            })}
          </Space>
        </Loader>

        <button
          className={'confirm-button' + (selected ? ' active' : '')}
          onClick={confirm}
          disabled={!selected}
        >
          Confirm
        </button>
      </div>
    </Drawer>
  );
};
