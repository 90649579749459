import { Space } from 'antd';
import { Link } from 'react-router-dom';

import { ChatCharacter } from '../../../api';
import { If } from '../../../common';

import './explore.component.css';

const Explore: React.FC<{
  data?: ChatCharacter[];
  style?: React.CSSProperties;
}> = ({ data, style }) => {
  return (
    <div className="explore-list" style={style}>
      {data?.map((character: ChatCharacter) => {
        return (
          <Link
            className="character-item"
            to={`/${character.handle}`}
            key={'character-' + character.id}
          >
            <div
              className="character-image"
              style={{
                backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 40%), url('${character.coverThumbnailUrl}')`,
                marginBottom: '8px',
              }}
            >
              <Space direction="vertical" align="center">
                <If condition={character.hasVoice}>
                  <div className="voice">Voice</div>
                </If>

                <div className="character-name">{character.name}</div>
              </Space>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Explore;
