import { AppLayout } from '../../../layouts/app/app-layout.component';
import { RouteData } from '../../../utils';
import PrivacyPolicy from './privacy-policy';
import TermsAndConditions from './terms-and-conditions';

export const LegalRoutes: RouteData[] = [
  {
    path: 'privacy',
    title: 'Privacy Policy',
    component: PrivacyPolicy,
    layout: AppLayout,
  },
  {
    path: 'tos',
    title: 'Terms & Conditions',
    component: TermsAndConditions,
    layout: AppLayout,
  },
];
