import {
  CharacterExploreRequest,
  ChatCharacter,
  useAPIRequest,
} from '../../../api';
import { Loader } from '../../../common';
import Explore from './explore.component';

import './index.component.css';

const Index: React.FC = () => {
  const { response, isLoading, error } = useAPIRequest<{
    data: ChatCharacter[];
  }>(CharacterExploreRequest);

  return (
    <Loader isLoading={isLoading} error={error}>
      <div className="index-container">
        <div className="title" style={{ marginBottom: '24px' }}>
          <span style={{ color: 'var(--orange3)' }}>Explore</span> AI Anime
          Girls
        </div>

        <Explore data={response?.data} />
      </div>
    </Loader>
  );
};

export default Index;
