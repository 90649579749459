import { Drawer, Space } from 'antd';
import Icon from '@ant-design/icons';
import { FC } from 'react';

import { useLayoutDisplay } from '../../hooks';
import {
  CloseSvg,
  DiscordSvg,
  FlameSvg,
  ProfileSvg,
  SubscriptionSvg,
} from '../common-svg.component';
import { MenuDrawerLink } from './menu-drawer-link.component';
import {
  PrivacySvg,
  SignOutSvg,
  SupportSvg,
  TermsSvg,
} from './menu-drawer-link-svg.component';
import {
  ChatSvg,
  ExploreSvg,
} from '../navigation-button/navigation-button-svg.component';
import { useInitStore, useAuthStore, useMiscStore } from '../../../stores';
import { WideButton } from '../wide-button/wide-button.component';
import { If } from '../if.component';

import './menu-drawer.component.css';

export const MenuDrawer: FC = () => {
  const { isMobile } = useLayoutDisplay();

  const { menuOpen, setMenuOpen } = useMiscStore();
  const { token, clearToken } = useAuthStore();
  const { user, resetInitData } = useInitStore();

  const close = () => setMenuOpen(false);

  const hasCredits = (user?.credits || 0) > 0;

  let height: number | undefined = undefined;

  if (isMobile) {
    if (token) {
      height = 520;

      if (hasCredits) height += 40;
    } else height = 300;
  }

  return (
    <Drawer
      open={menuOpen}
      placement={isMobile ? 'bottom' : 'right'}
      closeIcon={null}
      onClose={close}
      height={height}
    >
      <div className="menu-drawer-container">
        <div className="button-circle" onClick={close}>
          <Icon component={CloseSvg} />
        </div>

        <div className="title">More</div>

        <Space direction="vertical" size={18} style={{ padding: '0px 20px' }}>
          <If condition={token && isMobile}>
            <Space direction="vertical" size={18} style={{ width: '100%' }}>
              <MenuDrawerLink
                icon={ExploreSvg}
                iconWidth="24px"
                label="Explore"
                to="/"
                onClick={close}
              />

              <MenuDrawerLink
                icon={ChatSvg}
                iconWidth="24px"
                label="Messages"
                to="/messages"
                onClick={close}
              />

              <If condition={user?.subscription.active || hasCredits}>
                <MenuDrawerLink
                  icon={FlameSvg}
                  iconWidth="24px"
                  label="Credits"
                  to="/credits"
                  onClick={close}
                />
              </If>

              <MenuDrawerLink
                icon={SubscriptionSvg}
                iconWidth="24px"
                label="Subscription"
                to="/subscription"
                onClick={close}
              />

              <MenuDrawerLink
                icon={ProfileSvg}
                iconWidth="24px"
                label="Account"
                to="/account"
                onClick={close}
              />
            </Space>
          </If>

          <MenuDrawerLink
            icon={TermsSvg}
            label="Terms & Conditions"
            to="/tos"
            onClick={close}
          />

          <MenuDrawerLink
            icon={PrivacySvg}
            label="Privacy Policy"
            to="/privacy"
            onClick={close}
          />

          <MenuDrawerLink
            icon={SupportSvg}
            label="Support"
            to={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}
            target="_blank"
            onClick={close}
          />

          <If condition={!!token}>
            <MenuDrawerLink
              icon={SignOutSvg}
              label="Sign Out"
              to="/"
              onClick={() => {
                resetInitData();
                clearToken();
                close();
              }}
            />
          </If>
        </Space>

        <If condition={isMobile}>
          <div style={{ padding: '0px 20px', marginTop: '32px' }}>
            <WideButton
              label="Discord"
              to={process.env.REACT_APP_DISCORD}
              target="_blank"
            >
              <Icon component={DiscordSvg} style={{ width: '16px' }} />
            </WideButton>
          </div>
        </If>
      </div>
    </Drawer>
  );
};
