import ChatList from './chat-list.component';

import './messages.component.css';

const Messages: React.FC = () => {
  return (
    <div className="messages-container">
      <ChatList style={{ marginTop: '25px' }} />
    </div>
  );
};

export default Messages;
