import create from 'zustand';

import { AuthDrawerState } from '../common';

interface MiscStoreState {
  siderWidth: string;
  menuOpen: boolean;
  authOpen: boolean;
  authState: AuthDrawerState;
  creditsDrawerOpen: boolean;
  creditsDrawerInsufficientCredits: boolean;
  subscriptionModalOpen: boolean;
  setCreditsDrawerOpen: (open: boolean, insufficientFunds?: boolean) => void;
  setSubscriptionModalOpen: (open: boolean) => void;
  setMenuOpen: (open: boolean) => void;
  setAuthOpen: (open: boolean, state?: AuthDrawerState) => void;
}

export const useMiscStore = create<MiscStoreState>((set) => ({
  siderWidth: '250px',
  menuOpen: false,
  authOpen: false,
  creditsDrawerOpen: false,
  creditsDrawerInsufficientCredits: false,
  subscriptionModalOpen: false,
  authState: AuthDrawerState.SignUp,
  setCreditsDrawerOpen: (open: boolean, insufficientCredits = false) => {
    set({
      creditsDrawerOpen: open,
      creditsDrawerInsufficientCredits: insufficientCredits,
    });
  },
  setSubscriptionModalOpen: (open: boolean) => {
    set({ subscriptionModalOpen: open });
  },
  setMenuOpen: (open: boolean) => set({ menuOpen: open }),
  setAuthOpen: (
    open: boolean,
    state: AuthDrawerState = AuthDrawerState.SignUp,
  ) => {
    if (!open) set({ authOpen: open });
    else set({ authOpen: open, authState: state });
  },
}));
