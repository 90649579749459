import { FormInstance } from 'antd';

import { RequestError } from './interfaces';

export function formRequestErrorHandler(
  form: FormInstance<any>,
  callback?: (error: RequestError) => void,
) {
  return (error: RequestError) => {
    if (error.fields) form.setFields(error.fields);
    else {
      /*createNotification({
        key: 'serverError',
        message: error.message,
        type: NotificationType.Error,
      });*/
    }

    callback?.(error);
  };
}

export function requestErrorHandler(callback?: (error: RequestError) => void) {
  return (error: RequestError) => {
    /*createNotification({
      key: 'serverError',
      message: error.message,
      type: NotificationType.Error,
    });*/

    callback?.(error);
  };
}
