import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import { persist } from 'zustand/middleware';

interface AuthStoreState {
  token?: string;
  setToken: (token: string) => void;
  clearToken: () => void;
}

export const authStore = createVanilla<AuthStoreState>(
  persist<AuthStoreState>(
    (set) => ({
      token: undefined,
      setToken: (token: string) => set({ token }),
      clearToken: () => set({ token: undefined }),
    }),
    { name: 'auth' },
  ),
);

export const useAuthStore = create<AuthStoreState>(authStore);
