import { useRef } from 'react';
import { useParams } from 'react-router-dom';

import MediaViewer from './media-viewer/media-viewer.component';
import MessageList from './message-list/message-list.component';
import { ParsedMessage } from './message-list/parsed-message.interface';
import { useChat } from '../../../../common';

import './chat.component.css';

const Chat: React.FC = () => {
  const params = useParams();

  const {
    id: chatId,
    messages,
    loadMoreMessages,
    isLoadingMessages,
    viewedMedia,
    setViewedMedia,
    unlockMessageMedia,
    playPauseVoice,
    voicePlaying,
    character,
  } = useChat(params.handle);

  const contentRef = useRef<any>();

  return (
    <div className="chat-centered-container" ref={contentRef}>
      <MessageList
        messages={messages}
        loadMoreMessages={loadMoreMessages}
        isLoadingMessages={isLoadingMessages}
        unlockMessageMedia={(data: ParsedMessage) => {
          unlockMessageMedia(chatId, data?.id);
        }}
        viewMedia={(data: ParsedMessage) => setViewedMedia(data.media)}
        playPauseVoice={(data: ParsedMessage) => {
          if (data?.id || data?.voiceUrl) {
            playPauseVoice(chatId!, data?.id, data?.voiceUrl);
          }
        }}
        voicePlaying={voicePlaying}
        hasVoice={character?.hasVoice}
      />

      <MediaViewer
        media={viewedMedia}
        close={() => setViewedMedia(undefined)}
      />
    </div>
  );
};

export default Chat;
