import { FC } from 'react';
import Icon, { LoadingOutlined } from '@ant-design/icons';

import { PauseSvg, PlaySvg } from './mini-audio-player-svg.component';
import { If } from '../if.component';

import './mini-audio-player.component.css';

export const MiniAudioPlayer: FC<{
  isLoading?: boolean;
  isPlaying?: boolean;
  playPause?: () => void; // should call generate voice if it's not already generated
  buttonBackgroundColor?: string;
  buttonIconColor?: string;
  style?: React.CSSProperties;
}> = ({
  isLoading,
  isPlaying,
  playPause,
  buttonBackgroundColor,
  buttonIconColor,
  style,
}) => {
  return (
    <div style={style}>
      <button
        onClick={isLoading ? undefined : playPause}
        className={'mini-audio-player-button' + (isLoading ? ' loading' : '')}
        style={{
          backgroundColor: buttonBackgroundColor,
          color: buttonIconColor,
        }}
      >
        <If condition={isLoading}>
          <LoadingOutlined style={{ fontSize: '24px' }} spin />
        </If>

        <If condition={!isLoading}>
          <Icon component={isPlaying ? PauseSvg : PlaySvg} />
        </If>
      </button>
    </div>
  );
};
