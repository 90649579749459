import { AxiosRequestConfig } from 'axios';

import { transformMessageResponse } from './transform-message-response';

export const GenerateVoiceRequest = (
  chatId: string,
  messageId: string,
): AxiosRequestConfig => {
  return {
    url: `v1/chat/${chatId}/voice`,
    method: 'post',
    data: { messageId },
    transformResponse: transformMessageResponse,
  };
};
