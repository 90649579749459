import { Message } from './message.interface';

export function transformMessageResponse(response: string) {
  if (response.includes('"error"')) return JSON.parse(response);

  const { message, credits }: { message: Message; credits: number } =
    JSON.parse(response);

  return {
    message: { ...message, sent: new Date(message.sent) },
    credits,
  };
}
