import { AppLayout } from '../../../layouts/app/app-layout.component';
import { RouteData } from '../../../utils';
import Messages from './messages.component';

export const MessagesRoutes: RouteData[] = [
  {
    path: 'messages',
    title: 'Messages',
    component: Messages,
    layout: AppLayout,
    authRequired: true,
    flatHeader: true,
  },
];
