import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Layout } from 'antd';
import Icon from '@ant-design/icons';

import { If, useLayoutDisplay } from '../../common';
import { BackSvg, MenuSvg } from '../layout-header-svg.component';
import { useMiscStore } from '../../stores';
import { RouteData } from '../../utils';

const { Header } = Layout;

const AppHeader: FC<{
  routeData: RouteData;
  backPath: string;
  width?: string;
  maxWidth?: string;
  setVisible: (value: boolean) => void;
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({
  routeData,
  backPath,
  width,
  maxWidth,
  setVisible,
  style,
  ...props
}) => {
  const { setMenuOpen } = useMiscStore();

  const { isMobile } = useLayoutDisplay();

  useEffect(() => {
    setVisible(true);
  }, [routeData]);

  return (
    <Header
      style={{
        width,
        maxWidth,
        borderRadius: routeData.flatHeader ? 0 : undefined,
        ...style,
      }}
      {...props}
    >
      <Helmet>
        <title>AnimeGF.ai - Chat with Your AI Anime Girlfriend</title>
      </Helmet>

      <div className="header-container">
        <Link to={backPath}>
          <div className="button-circle">
            <Icon component={BackSvg} />
          </div>
        </Link>

        <div className="title absolute">{routeData.title}</div>

        {/* wrapping with div to maintain space when not on mobile */}
        <div>
          <If condition={isMobile}>
            <button className="button-menu" onClick={() => setMenuOpen(true)}>
              <Icon component={MenuSvg} />
            </button>
          </If>
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
