import { FC } from 'react';
import { Form, Input, Button } from 'antd';
import {
  formRequestErrorHandler,
  ResetPasswordRequest,
  useAPIRequest,
} from '../../../api';
import { AuthDrawerState, StatefulLink } from '../../../common';
import { createNotification, NotificationType } from '../../../utils';
import { useMiscStore } from '../../../stores';

import './auth-form.component.css';

const ForgotPassword: FC = () => {
  const [form] = Form.useForm();

  const setAuthOpen = useMiscStore((state) => state.setAuthOpen);

  const { request, isLoading } = useAPIRequest<{
    success: boolean;
  }>((params: { email: string }) => ResetPasswordRequest(params.email), {
    immediate: false,
    onSuccess: (data, params: { email: string }) => {
      createNotification({
        key: 'resetPassword',
        message: `Password reset instructions sent to ${params?.email}. Please check your inbox.`,
        type: NotificationType.Success,
        duration: 6,
      });

      setAuthOpen(true, AuthDrawerState.SignIn);
    },
    onError: formRequestErrorHandler(form),
  });

  return (
    <div className="auth-form">
      <div className="title">Forgot your password?</div>

      <Form
        form={form}
        onFinish={request}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        disabled={isLoading}
        style={{ width: '100%' }}
      >
        <div className="description">
          Fill in the email below and we'll send you password reset
          instructions!
        </div>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Email must not be empty',
            },
            {
              type: 'email',
              message: 'Email is invalid',
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item style={{ marginTop: '8px', marginBottom: '0px' }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Reset Password
          </Button>
        </Form.Item>
      </Form>

      <div className="switch">
        Remembered your password?{' '}
        <StatefulLink
          onClick={() => setAuthOpen(true, AuthDrawerState.SignIn)}
          disabled={isLoading}
          className="link"
        >
          Sign In
        </StatefulLink>
      </div>
    </div>
  );
};

export default ForgotPassword;
