import { Grid } from 'antd';

import { useAuthStore, useMiscStore } from '../../stores';

const { useBreakpoint } = Grid;

export function useLayoutDisplay(noSider?: boolean) {
  const siderWidth = useMiscStore((state) => state.siderWidth);
  const token = useAuthStore((state) => state.token);

  const breakpoint = useBreakpoint();

  const isTablet = breakpoint.lg || breakpoint.xl;
  const displaySider = !!token && !noSider && isTablet;
  const headerWidth = displaySider ? `calc(100% - ${siderWidth})` : '100vw';

  return {
    displaySider,
    isMobile: !isTablet,
    headerWidth,
    breakpoint,
    siderWidth: displaySider ? siderWidth : 0,
  };
}
