import { AppLayout } from '../../../layouts/app/app-layout.component';
import { RouteData } from '../../../utils';
import Credits from './credits.component';

export const CreditsRoutes: RouteData[] = [
  {
    path: 'credits',
    title: 'Credits',
    component: Credits,
    authRequired: true,
    flatHeader: true,
    layout: AppLayout,
  },
];
