import { forwardRef, useEffect, useRef } from 'react';
import Icon, { PlayCircleFilled } from '@ant-design/icons';

import { AudioPlayer, If, MiniAudioPlayer } from '../../../../../common';
import { ParsedMessageType } from './parsed-message-type.enum';
import { ParsedMessagePosition } from './parsed-message-position.enum';
import { ParsedMessage } from './parsed-message.interface';
import { MessageSpinnerSvg } from './message-box-svg.component';
import { MediaType } from '../../../../../api';
import { capitalizeFirstLetter } from '../../../../../utils';
import MessageTextItems from './message-text-items.component';
import { TextType } from './text-type.enum';

import './message-box.component.css';

const MessageBox = forwardRef<
  any,
  {
    data: ParsedMessage;
    unlockMessageMedia?: (data: ParsedMessage) => any;
    viewMedia?: (data: ParsedMessage) => any;
    playPauseVoice?: (data: ParsedMessage) => any;
    voicePlaying?: boolean;
    hasVoice?: boolean;
  }
>(
  (
    {
      data,
      unlockMessageMedia,
      viewMedia,
      playPauseVoice,
      voicePlaying,
      hasVoice,
    },
    ref,
  ) => {
    const className: string[] = ['message-box', data.position];
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.addEventListener('contextmenu', (event) => {
          event.preventDefault();
        });
      }
    }, [videoRef]);

    const getTime = (date: Date) => {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return hours + ':' + minutes;
    };

    const isTextOrVoice =
      data.type === ParsedMessageType.Text ||
      data.type === ParsedMessageType.Voice;

    if (isTextOrVoice) className.push('text-or-voice');

    if (data.type === ParsedMessageType.Voice) className.push('voice');
    else className.push('non-voice');

    let waveColor: string;
    let buttonBackgroundColor: string;
    let buttonIconColor: string;

    if (data.position === ParsedMessagePosition.Left) {
      waveColor = '#FF8B4A';
      buttonBackgroundColor = 'var(--white)';
      buttonIconColor = 'var(--orange1)';
    } else {
      waveColor = '#000000';
      buttonBackgroundColor = '#1D1D1F';
      buttonIconColor = 'var(--white)';
    }

    const parseText = (value?: string) => {
      if (!value) return [];

      const regex = /(\*[^*]+\*\s*)|([^*]+)/g;
      const result = [];

      let match;

      while ((match = regex.exec(value))) {
        if (match[1]) {
          result.push({ text: match[1], type: TextType.Stars });
        } else if (match[2] && match[2] !== ' ') {
          result.push({ text: match[2].trim(), type: TextType.Normal });
        }
      }

      return result;
    };

    const parsedText = parseText(data.value);

    const hasNormalText = parsedText.some(
      (item) => item.type === TextType.Normal,
    );

    return (
      <div className={className.join(' ')} key={data.key} ref={ref}>
        <If condition={!!data.value || data.voiceUrl}>
          <If condition={data.type === ParsedMessageType.Text}>
            <div className="mini-audio-player-wrapper">
              <MessageTextItems items={parsedText} />

              {((data.position === ParsedMessagePosition.Left &&
                hasNormalText &&
                hasVoice) ||
                data.voiceUrl) && (
                <MiniAudioPlayer
                  buttonBackgroundColor={buttonBackgroundColor}
                  buttonIconColor={buttonIconColor}
                  playPause={() => playPauseVoice?.(data)}
                  isLoading={data.voiceLoading}
                  isPlaying={voicePlaying}
                  style={{ marginLeft: data.value ? '10px' : undefined }}
                />
              )}
            </div>
          </If>

          <If condition={data.type === ParsedMessageType.Voice}>
            <AudioPlayer
              waveColor={waveColor}
              progressColor="white"
              buttonBackgroundColor={buttonBackgroundColor}
              buttonIconColor={buttonIconColor}
              url={data.value}
              marginRight="5px"
              barWidth={3}
              barRadius={20}
            />
          </If>
        </If>

        <If condition={data.media}>
          <If condition={data.media?.unlocked}>
            <div className="pointer" onClick={() => viewMedia?.(data)}>
              <If condition={data.media?.type === MediaType.Video}>
                <PlayCircleFilled
                  className="center icon"
                  style={{ fontSize: '50px' }}
                />

                <div className="top-right unlock duration">
                  {data?.media?.duration}
                </div>
              </If>

              <div
                style={{ backgroundImage: `url('${data.media?.previewUrl}')` }}
                className="media"
              />
            </div>
          </If>

          <If condition={!data.media?.unlocked}>
            <div className="pointer" onClick={() => unlockMessageMedia?.(data)}>
              <div
                style={{ backgroundImage: `url('${data.media?.previewUrl}')` }}
                className="media"
              />

              <div className="center unlock price">${data.media?.price}</div>

              <div className="bottom-center unlock instructions">
                Unlock {capitalizeFirstLetter(data.media?.type || '')}
              </div>

              <If condition={data.media?.type === MediaType.Video}>
                <div className="top-right unlock duration">
                  {data?.media?.duration}
                </div>
              </If>
            </div>
          </If>
        </If>

        <If condition={data.loading}>
          <Icon
            component={MessageSpinnerSvg}
            style={{ marginLeft: '10px', marginRight: '10px' }}
          />
        </If>

        <If condition={!data.loading}>
          <div className="time">{getTime(data.sent)}</div>
        </If>

        <If condition={isTextOrVoice}>
          <div className="notch" />
        </If>
      </div>
    );
  },
);

export default MessageBox;
