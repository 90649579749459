export enum UserType {
  Admin = 'admin',
  Creator = 'creator',
  Telegram = 'telegram',
  App = 'app', // for future use
}

export function getUserTypeLabel(type: UserType): string {
  switch (type) {
    case UserType.Admin:
      return 'Admin';
    case UserType.Creator:
      return 'Creator';
    case UserType.Telegram:
      return 'Telegram';
    case UserType.App:
      return 'App';
  }
}
