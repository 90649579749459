import { useState } from 'react';
import { Space } from 'antd';

import {
  useAPIRequest,
  UpdateSubscriptionRequest,
  UserParams,
} from '../../../api';
import { formatDate } from '../../../utils';
import { useInitStore } from '../../../stores';
import SubscriptionBenefits from './subscription-benefits.component';
import CancelSubscriptionModal from './cancel-subscription-modal.component';
import { If, Loader } from '../../../common';

import './active-plan.component.css';

const ActivePlan: React.FC = () => {
  const { user, setUser } = useInitStore();

  const [isOpen, setOpen] = useState(false);

  const { request, isLoading, error } = useAPIRequest<UserParams>(
    UpdateSubscriptionRequest,
    {
      immediate: false,
      onSuccess: setUser,
    },
  );

  return (
    <>
      <Loader isLoading={isLoading} error={error}>
        <div className="active-plan-container">
          <Space
            direction="horizontal"
            align="center"
            size={40}
            className="active-plan-inner-container"
            wrap
          >
            <div className="active-plan-wrapper">
              <div
                style={{
                  fontSize: '32px',
                  color: 'var(--orange2)',
                }}
              >
                Premium Plan
              </div>

              <div style={{ padding: '20px', textAlign: 'center' }}>
                <If condition={user?.subscription.cancelAtPeriodEnd}>
                  Your premium plan will remain active until{' '}
                  {' ' + formatDate(user?.subscription.periodEnd)}. Auto renewal
                  is off.
                </If>

                <If condition={!user?.subscription.cancelAtPeriodEnd}>
                  Your premium plan will renew on
                  {' ' + formatDate(user?.subscription.periodEnd)}.
                </If>
              </div>

              <If condition={user?.subscription.cancelAtPeriodEnd}>
                <div
                  className="cancel-subscription"
                  onClick={() => request(false)}
                >
                  Restore Auto Renewal
                </div>
              </If>

              <If condition={!user?.subscription.cancelAtPeriodEnd}>
                <div
                  className="cancel-subscription"
                  onClick={() => setOpen(true)}
                >
                  Cancel Subscription
                </div>
              </If>
            </div>

            <SubscriptionBenefits />
          </Space>
        </div>
      </Loader>

      <CancelSubscriptionModal
        isOpen={isOpen}
        close={() => setOpen(false)}
        confirm={() => request(true)}
      />
    </>
  );
};

export default ActivePlan;
