import { Typography } from 'antd';
import { LegalRenderer, SupportEmail } from './components';
import { LegalItem } from './interfaces';

const { Title } = Typography;

const data: LegalItem = {
  content: [
    <>
      AnimeGF is an online chat application that uses artificial intelligence to
      create virtual and fictional characters ("AI Companions"). AnimeGF also
      generates images and messages for interaction with these AI Companions.
      Some services offered by AnimeGF may require you to create a user account.
      In this Privacy Policy, "you" and "your" refer to you as the user of
      AnimeGF.
      <Title>GDPR</Title>
      <div>
        If you live in the European Union, you are protected by and have
        additional rights under the General Data Protection Regulation 2016/679
        (GDPR).
      </div>
    </>,
  ],
  children: [
    {
      title: 'Definitions',
      content:
        'We are committed to respecting your privacy, and our services will always be provided with your consent. This document explains how your data is processed when you visit the website and use our services. "Content" refers to information you provide for registration and interaction with AI Companions, including Personal Data. "Consent" is your clear agreement to the processing of your Personal Data through a specific and informed action. "Performance of our Services" includes actions necessary for us to provide our services. "Personal Data" is information that identifies you directly or indirectly, such as your name, address, date of birth, gender, and other personal details. "Platform" means AnimeGF. "Processing" involves any operation performed on Personal Data, including collection, recording, storage, use, and deletion. "Services" are the steps enabling you to interact with AI Companions. "Subscription" is an arrangement between AnimeGF and you to use the services. "User," "you," and "your" refer to a person or company using AnimeGF or its services. "Visitor" means anyone browsing the website without a subscription. "Website" refers to AnimeGF.ai.',
    },
    {
      title: 'Purpose of Personal Data Processing',
      content:
        'When you visit the website, we collect data to analyze and understand your behavior, improve our services, enhance data security, and assess the effectiveness of our marketing campaigns. When you register and use our services, we collect Personal Data such as your name, date of birth, and usage information. This data is used to set up your account, tailor the services to your needs, and develop new tools. We also automatically receive information from your browser or mobile device, such as your location and IP address, which is treated as non-Personal Data unless required by law. This information helps us improve our services and understand how users interact with our platform. If you do not provide your Personal Data, we may not be able to offer our services and may have to cancel your subscription, notifying you accordingly. You consent to the processing of your Personal Data for the performance of our services, to handle complaints, for business administration and management, and for marketing analysis and research.',
    },
    {
      title: 'Personal Data collection',
      children: [
        {
          title: 'Personal Data collection for Visitors',
          content:
            'The website can be visited anonymously. It may process anonymous metadata through cookies, including the URL of the requested file, the amount of data transferred, the date and time of the request, browser information, operating system type, IP address, internet service provider, and reference links like user clicks. This metadata cannot be used to obtain personally identifiable information and is only used for general purposes such as technical improvements, statistical, and marketing analysis. When you visit the website, we may automatically receive some information for each page accessed, including the type of browser and operating system used, the IP address of the accessing device (e.g., PC, tablet), the date and time of your visit, your server address, the previous website visited, the average duration of page views, and your navigation behavior and preferences.',
        },
        {
          title: 'Personal Data collection for Users',
          content:
            'We collect Personal Data from and about you using various methods, including information you provide when registering for a subscription, content you upload to your personal account, and communications with us via writing, telephone, fax, website registration, email, or other electronic means. These communications may be monitored, recorded, and stored. We also collect technical data automatically through your interactions with the AI Companions, including data about your equipment, browsing actions, and patterns. Our moderation team ensures compliance with laws, and we gather Personal Data using cookies, server logs, and similar technologies. Additionally, we may receive technical data from other websites using our cookies and from third-party sources such as analytics providers (e.g., Google Analytics), advertising networks/agencies, and search information providers.',
        },
      ],
    },
    {
      title: 'Marketing',
      content: `We may send you marketing information about our services, along with alerts, newsletters, and invitations to events or functions that we think might interest you. Additionally, we may update you with relevant information, such as legal or commercial news. These communications can be delivered through post, telephone, email, or other digital channels.`,
    },
    {
      title: 'Third Party Marketing',
      content: `We will obtain your explicit opt-in consent before sharing your Personal Data with any company outside Usway for marketing purposes.

        You can stop receiving marketing messages from us or third parties at any time by logging into the website or third-party websites and adjusting your marketing preferences, or by following the opt-out links included in any marketing message sent to you.`,
    },
    {
      title: 'Cookies',
      content:
        'You can configure your browser to refuse all or some cookies or to notify you when a website sets or accesses cookies. Please note that if you disable or refuse cookies, some parts of the website may become inaccessible or may not function properly.',
    },
    {
      title: 'Change of purpose',
      content:
        'We will only use your Personal Data for the purposes for which it was collected, unless we determine that it needs to be used for another reason that is compatible with the original purpose. If you would like an explanation of how the new purpose is compatible with the original one, please contact us. If we need to use your Personal Data for an unrelated purpose, we will notify you and explain the legal basis for doing so. Please note that we may process your Personal Data without your knowledge or consent when required or permitted by law.',
    },
    {
      title: 'Sharing your personal information',
      content: `AnimeGF may share your information with service providers we collaborate with to deliver our services, our professional advisers (such as lawyers, accountants, IT or public relations consultants) when necessary, legal and regulatory authorities as required by law, and our employees. We will not disclose, sell, trade, or otherwise transfer your personal information to any third parties without your consent, unless otherwise stated in this Privacy Policy. This Privacy Policy does not cover the practices of service providers and other third parties that we do not own, control, or manage.`,
    },
    {
      title: 'Sensitive Information',
      content:
        'We will never ask you to provide Personal Data that reveals racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data used for identification, or health information. If you choose to provide us with such sensitive information, you understand that you are doing so voluntarily.',
    },
    {
      title: 'Controller',
      content: (
        <>
          This Privacy Policy is issued on behalf of AnimeGF, so when we mention
          "we" or "us" in this Privacy Policy, we are referring to AnimeGF. We
          have appointed a Controller within our team who is responsible for
          overseeing questions related to this Privacy Policy. If you have any
          questions about this Privacy Policy, including requests to exercise
          your legal rights, please contact the Controller using the details
          below. Contact details: <SupportEmail />
        </>
      ),
    },
    {
      title: 'Request to access your Personal Data',
      content:
        'You have the right to access your Personal Data at any time. This allows you to receive a copy of the Personal Data we hold about you and verify that we are processing it lawfully.',
    },
    {
      title: 'Period',
      content:
        'We retain your personal information as long as your account is active or as necessary to fulfill the purposes for which it was collected or to provide you with our services, unless otherwise required by law. After you terminate your account, we will retain your personal information for a period of time, typically for six years from the date your account is closed. Retention periods may change based on business or regulatory requirements.',
    },
    {
      title: 'Personal information of minors',
      content:
        'AnimeGF does not provide services or collect personal information from anyone under 21 years of age, or the equivalent minimum age in your jurisdiction. Our services are intended only for adults who are 21 years or older. If we discover that we have been misled by an individual under 21, or the equivalent minimum age in their jurisdiction, we will take steps to delete their information promptly and block their access.',
    },
    {
      title: 'Third-party links',
      content:
        'The website may include links to third-party websites, plug-ins, and applications. Clicking on these links or enabling these connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. We encourage you to read the Privacy Policy of every website you access through our website.',
    },
    {
      title: 'Merge of Company',
      content: `If AnimeGF merges with or is acquired by another company or organization, or sells all or a portion of its assets, your personal information may be disclosed to our advisers, any prospective purchaser, or the purchaser's advisers, and may be among the assets transferred. However, your personal information will always remain subject to this Privacy Policy.`,
    },
    {
      title: 'Right to rectification',
      content: `You have the right to request the Controller to rectify inaccurate Personal Data concerning you without undue delay.
    
      This allows you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.`,
    },
    {
      title: 'Right to request restriction of processing of your Personal Data',
      content:
        'This allows you to ask our Controller to suspend the processing of your Personal Data in the following scenarios: (a) if you want us to verify the accuracy of your Personal Data; (b) where our processing is unlawful and you prefer suspension over erasure; (c) where you need us to hold the data to establish, exercise, or defend legal claims; or (d) if you have objected to our processing of your Personal Data and we need to verify whether we have overriding legitimate grounds to continue using it.',
    },
    {
      title: 'Withdrawal of Consent',
      content: `We are committed to making it as easy to withdraw consent as it is to give it.

    You have the right to withdraw your consent at any time. Withdrawing consent will not affect the lawfulness of the processing of your Personal Data based on consent before its withdrawal.
    
    If you withdraw your consent, we will no longer be able to provide our services to you and will take reasonable steps, considering available technology and means, to delete your user account promptly. We will also delete the Personal Data we have collected from you for internal use.
    
    If you withdraw your consent, we are not responsible for content that remains hosted on third-party websites.`,
    },
    {
      title: 'Right to be forgotten',
      content: `You have the right to request the Controller to erase your Personal Data without undue delay, and the Controller will undertake to do so promptly.

    This allows you to ask us to delete or remove Personal Data where there is no valid reason for us to continue processing it. You also have the right to request the deletion or removal of your Personal Data if you have successfully exercised your right to object to processing, if we have processed your information unlawfully, or if we are required to erase your Personal Data to comply with local law. However, please note that we may not always be able to comply with your request for erasure due to specific legal reasons, which will be communicated to you at the time of your request if applicable.
    
    If you believe that the processing of your Personal Data impacts your fundamental rights and freedoms, please object immediately. You also have the right to object if we are processing your Personal Data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information that override your rights and freedoms.`,
    },
    {
      title: 'Time limit to respond',
      content: `
    We aim to respond to all legitimate requests within one month. Occasionally, it may take longer if your request is particularly complex or if you have made multiple requests. In such cases, we will notify you and keep you updated.`,
    },
    {
      title: 'Data Security',
      content: `We have implemented appropriate security measures to prevent your Personal Data from being accidentally lost, used, accessed without authorization, altered, or disclosed.

    We have established procedures to handle any suspected Personal Data breaches and will notify you and any applicable regulators of a breach when legally required to do so.`,
    },
    {
      title:
        'Changes to the Privacy Policy and your duty to inform us of changes',
      content: `We may revise this Privacy Policy from time to time and make necessary changes. This version of the Privacy Policy will govern our use of your information. If we make any significant changes to how we use your Personal Data, we will notify you by posting the changes and sending you an email to obtain your approval. By continuing to access or use the services after the changes become effective, you agree to be bound by the revised Privacy Policy.
      
      Please keep a copy of this document and feel free to ask us any questions or address any concerns you have regarding our Privacy Policy.`,
    },
  ],
};

const PrivacyPolicy: React.FC = () => {
  return (
    <LegalRenderer
      data={data}
      lastUpdate="June 5, 2024"
      style={{ padding: '40px' }}
    />
  );
};

export default PrivacyPolicy;
