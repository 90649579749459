import { AxiosRequestConfig } from 'axios';

import { SubscriptionParams } from '../user';

export const UpdateSubscriptionRequest = (
  cancelAtPeriodEnd: boolean,
): AxiosRequestConfig => {
  return {
    url: 'v1/billing/subscription',
    method: 'put',
    data: { cancelAtPeriodEnd },
    transformResponse: (response: string) => {
      if (response.includes('"error"')) return JSON.parse(response);

      const { subscription, ...data }: { subscription: SubscriptionParams } =
        JSON.parse(response);

      return {
        ...data,
        subscription: {
          ...subscription,
          periodEnd: subscription.periodEnd
            ? new Date(subscription.periodEnd)
            : null,
        },
      };
    },
  };
};
