import { Layout } from 'antd';
import { FC, PropsWithChildren, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useLayoutDisplay, useScrollToTopOnRouteChange } from '../../common';
import { RouteData, useAppQueryEffect } from '../../utils';
import { useLayoutChildren } from '../use-layout-children.hook';
import { AppSider } from '../app-sider/app-sider.component';
import Global from '../../global.component';
import AppHeader from './app-header.component';
import AppFooter from './app-footer.component';
import { useAuthStore } from '../../stores';

const { Content } = Layout;

export const AppLayout: FC<
  PropsWithChildren<{
    withInit?: boolean;
    routeData: RouteData;
  }>
> = ({ children, routeData, withInit = false }) => {
  const params = useParams();

  const layoutChildren = useLayoutChildren(children);

  const { headerWidth, isMobile } = useLayoutDisplay();

  const token = useAuthStore((state) => state.token);

  const [isHeaderVisible, setHeaderVisible] = useState<boolean>(false);
  const [isFooterVisible, setFooterVisible] = useState<boolean>(false);

  useAppQueryEffect();
  useScrollToTopOnRouteChange();

  const LayoutHeader = routeData.header || AppHeader;
  const LayoutFooter = routeData.footer || AppFooter;

  const maxWidth = isMobile ? undefined : routeData.maxWidth || '800px';

  const headerHeight = '96px';
  const footerHeight = '96px';

  const calc = ['100svh'];

  if (isHeaderVisible) calc.push(headerHeight);
  if (isFooterVisible) calc.push(footerHeight);

  const height = `calc(${calc.join(' - ')})`;

  const getBackPath = () => {
    if (routeData.backPath) return routeData.backPath;

    if (routeData.backPathFactory) {
      return routeData.backPathFactory(params, !!token);
    }

    return '/';
  };

  return (
    <Layout>
      <AppSider routeData={routeData} />

      <Layout className="inner-layout">
        <LayoutHeader
          routeData={routeData}
          backPath={getBackPath()}
          width={headerWidth}
          maxWidth={maxWidth}
          setVisible={setHeaderVisible}
        />

        <Content
          className="layout-content-offset"
          style={{
            maxWidth,
            height,
            marginTop: isHeaderVisible ? headerHeight : undefined,
          }}
        >
          {withInit ? layoutChildren : children}
        </Content>

        <LayoutFooter
          routeData={routeData}
          maxWidth={maxWidth}
          setVisible={setFooterVisible}
        />
      </Layout>

      <Global />
    </Layout>
  );
};
