import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import './wide-button.component.css';

export const WideButton: FC<
  PropsWithChildren<{
    label: string;
    accent?: boolean;
    light?: boolean;
    to?: string;
    target?: string;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
  }>
> = ({
  children,
  label,
  accent,
  light,
  to,
  target,
  onClick,
  className,
  style,
}) => {
  const classNames = ['wide-button'];

  if (accent) classNames.push('accent');
  if (light) classNames.push('light');
  if (className) classNames.push(className);

  if (to) {
    return (
      <Link
        className={classNames.join(' ')}
        style={style}
        to={to}
        target={target}
        onClick={onClick}
      >
        {children && <div style={{ marginRight: '6px' }}>{children}</div>}

        <div>{label}</div>
      </Link>
    );
  }

  return (
    <div className={classNames.join(' ')} style={style} onClick={onClick}>
      {children && <div style={{ marginRight: '6px' }}>{children}</div>}

      <div>{label}</div>
    </div>
  );
};
