import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { jwtDecode } from 'jwt-decode';

import {
  AuthResponse,
  formRequestErrorHandler,
  useAPIRequest,
  AcceptInvitationRequest,
} from '../../../api';
import { useAuthStore, useInitStore, useMiscStore } from '../../../stores';
import { AuthDrawerState, CenteredContainer } from '../../../common';

import '../../../common/components/auth-drawer/auth-form.component.css';

const { Text, Title } = Typography;

const Invitation: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const setToken = useAuthStore((state) => state.setToken);
  const setAuthOpen = useMiscStore((state) => state.setAuthOpen);

  const { queryValues, forwardedToken } = useInitStore();

  useEffect(() => {
    if (queryValues) {
      if (!forwardedToken) navigate('/');
    }
  }, [queryValues, forwardedToken]);

  const { email, name } = forwardedToken
    ? (jwtDecode(forwardedToken) as any)
    : ({} as any);

  const { request, isLoading } = useAPIRequest<AuthResponse>(
    (params: { password: string; confirmPassword: string }) =>
      AcceptInvitationRequest({
        token: forwardedToken as string,
        password: params.password,
      }),
    {
      immediate: false,
      onSuccess: (response) => setToken(response.token),
      onError: formRequestErrorHandler(form, (error) => {
        if (!error.fields) {
          navigate('/');
          setAuthOpen(true, AuthDrawerState.SignIn);
        }
      }),
    },
  );

  return (
    <CenteredContainer className="auth-form">
      <Title style={{ marginBottom: '0.2em' }}>
        {name}, you've been invited to AnimeGF!
      </Title>

      <div style={{ marginBottom: '1em' }}>
        <Text>Please create a password to get started...</Text>
      </div>

      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={request}
        autoComplete="off"
        requiredMark={false}
        disabled={isLoading}
      >
        <Form.Item>
          <Input value={email} disabled />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Password must not be empty' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item
          name="confirm_password"
          rules={[
            { required: true, message: 'Confirm password must not be empty' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value)
                  return Promise.resolve();

                return Promise.reject(new Error("Passwords don't match"));
              },
            }),
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Confirm password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="auth-form-button"
            loading={isLoading}
          >
            Accept invitation
          </Button>
        </Form.Item>
      </Form>
    </CenteredContainer>
  );
};

export default Invitation;
