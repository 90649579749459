import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import { persist } from 'zustand/middleware';

interface PersistedStoreState {
  defaultPath: string | undefined;
  setDefaultPath: (value: string | undefined) => void;
}

export const persistedStore = createVanilla<PersistedStoreState>(
  persist<PersistedStoreState>(
    (set) => ({
      defaultPath: '/',
      setDefaultPath: (value: string | undefined) => {
        set({ defaultPath: value });
      },
    }),
    { name: 'persisted' },
  ),
);

export const usePersistedStore = create<PersistedStoreState>(persistedStore);
