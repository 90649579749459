import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Layout } from 'antd';
import Icon from '@ant-design/icons';

import { AuthDrawerState, If, LogoIcon, useLayoutDisplay } from '../../common';
import { useAuthStore, useMiscStore } from '../../stores';
import { RouteData } from '../../utils';
import { MenuSvg } from '../layout-header-svg.component';

import './index-header.component.css';

const { Header } = Layout;

const IndexHeader: FC<{
  routeData: RouteData;
  backPath: string;
  width?: string;
  maxWidth?: string;
  setVisible: (value: boolean) => void;
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({
  routeData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  backPath,
  width,
  maxWidth,
  setVisible,
  style,
  ...props
}) => {
  const token = useAuthStore((state) => state.token);

  const { setMenuOpen, setAuthOpen } = useMiscStore();

  const { isMobile } = useLayoutDisplay();

  const isVisible = !token || isMobile;

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  if (!isVisible)
    return (
      <Helmet>
        <title>AnimeGF.ai - Chat with Your AI Anime Girlfriend</title>
      </Helmet>
    );

  return (
    <Header
      style={{
        width,
        maxWidth,
        borderRadius: routeData.flatHeader ? 0 : undefined,
        ...style,
      }}
      {...props}
    >
      <Helmet>
        <title>AnimeGF.ai - Chat with Your AI Anime Girlfriend</title>
      </Helmet>

      <div className="header-container">
        <If condition={!token || isMobile}>
          <div className="logo">
            <Icon component={LogoIcon} style={{ marginRight: '10px' }} />
            AnimeGF
          </div>
        </If>

        <If condition={token && isMobile}>
          <button className="button-menu" onClick={() => setMenuOpen(true)}>
            <Icon component={MenuSvg} />
          </button>
        </If>

        <If condition={!token}>
          <button
            className="button-auth"
            onClick={() => setAuthOpen(true, AuthDrawerState.SignIn)}
          >
            Sign In
          </button>
        </If>
      </div>
    </Header>
  );
};

export default IndexHeader;
