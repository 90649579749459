import { FC } from 'react';

import './centered-container.component.css';

export const CenteredContainer: FC<{
  className?: string;
  wrapperStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  vertical?: boolean;
  [key: string]: any;
}> = ({
  children,
  wrapperStyle,
  className,
  style,
  vertical = false,
  ...props
}) => {
  const wrapperClassNames: string[] = ['centered-container'];

  if (vertical) wrapperClassNames.push('centered-container-vertical');

  return (
    <div className={wrapperClassNames.join(' ')} style={wrapperStyle}>
      <div className={className} style={style} {...props}>
        {children}
      </div>
    </div>
  );
};
