import { AxiosRequestConfig } from 'axios';

import { transformMessageResponse } from './transform-message-response';

export const UnlockMessageMediaRequest = (
  chatId: string,
  messageId: string,
): AxiosRequestConfig => {
  return {
    url: `v1/chat/${chatId}/unlock-message-media`,
    method: 'post',
    data: { messageId },
    transformResponse: transformMessageResponse,
  };
};
