import create from 'zustand';

import { UserParams, ReadUserRequest, useAPIRequest } from '../api';
import { isLocal } from '../common';
import { QueryValues } from '../utils';
import { setDatadogLogsUser } from '../third-party/libraries/datadog-logs';

interface InitStoreState {
  initialized: boolean;
  user?: UserParams;
  setUser: (user: UserParams) => void;
  setEmail: (email: string, emailConfirmed?: boolean) => void;
  setCredits: (credits: number) => void;
  resetInitData: () => void;
  setInitialized: () => void;
  forwardedToken?: string; // invitation or forgot password
  setForwardedToken: (token: string) => void;
  queryValues?: QueryValues;
  setQueryValues: (queryValues: QueryValues) => void;
}

export const useInitStore = create<InitStoreState>((set) => ({
  initialized: false,
  projects: [],
  setUser: (user: UserParams) => {
    set({ user });

    if (!isLocal()) {
      setDatadogLogsUser(user);
    }
  },
  setEmail: (email: string, emailConfirmed?: boolean) => {
    set((prev) => ({
      user: { ...(prev.user as UserParams), email, emailConfirmed },
    }));
  },
  setCredits: (credits: number) => {
    set((prev) => ({
      user: { ...(prev.user as UserParams), credits },
    }));
  },
  resetInitData: () => {
    set({ initialized: false, user: undefined });
  },
  setInitialized: () => set({ initialized: true }),
  setForwardedToken: (token: string) => {
    set({ forwardedToken: token });
  },
  setQueryValues: (queryValues: QueryValues) => set({ queryValues }),
}));

export const useRefreshUser = () => {
  const setUser = useInitStore((state) => state.setUser);

  const { request, response } = useAPIRequest<UserParams>(ReadUserRequest, {
    immediate: false,
    onSuccess: setUser,
  });

  return { refreshUser: request, user: response };
};
