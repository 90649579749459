import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Avatar, Layout, Space } from 'antd';
import Icon, { ClearOutlined } from '@ant-design/icons';

import { useChatStore, useInitStore } from '../../../stores';
import { If, VerifiedSvg, useLayoutDisplay } from '../../../common';
import {
  ChatParams,
  ClearChatRequest,
  UserType,
  useAPIRequest,
} from '../../../api';
import { RouteData, confirmDangerousAction } from '../../../utils';
import { BackSvg } from '../../layout-header-svg.component';

import './chat-header.component.css';

const { Header } = Layout;

const ChatHeader: FC<{
  routeData: RouteData;
  backPath: string;
  width?: string;
  maxWidth?: string;
  setVisible: (value: boolean) => void;
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({
  routeData,
  backPath,
  width,
  maxWidth,
  setVisible,
  style,
  ...props
}) => {
  const user = useInitStore((state) => state.user);

  const { isMobile } = useLayoutDisplay();

  const { id, character, clear, setSendWelcomeMessageFlag } = useChatStore();

  const { request: clearChat } = useAPIRequest<ChatParams>(ClearChatRequest, {
    immediate: false,
    onSuccess: (chat) => {
      clear();

      if (chat.character.hasWelcomeMessage && chat.totalMessages === 0) {
        setTimeout(() => setSendWelcomeMessageFlag(true), 1000);
      }
    },
  });

  useEffect(() => {
    setVisible(true);
  }, [routeData]);

  return (
    <Header
      style={{
        width,
        maxWidth,
        borderRadius: routeData.flatHeader ? 0 : undefined,
        ...style,
      }}
      {...props}
    >
      <Helmet>
        <title>{character ? character?.name + ' - AnimeGF' : 'AnimeGF'} </title>
      </Helmet>

      <div className="header-container">
        <Space direction="horizontal" size={12}>
          <Link to={backPath}>
            <div className="button-circle">
              <Icon component={BackSvg} />
            </div>
          </Link>

          <Link to={`/${character?.handle}`}>
            <Avatar
              size={48}
              src={character?.avatarUrl}
              style={{ cursor: 'pointer' }}
            />
          </Link>
        </Space>

        <Link
          to={`/${character?.handle}`}
          className={'title' + (isMobile ? '' : ' absolute')}
          style={{ cursor: 'pointer' }}
        >
          <div style={{ marginRight: '4px' }}>{character?.name}</div>{' '}
          {character?.name && <Icon component={VerifiedSvg} />}
        </Link>

        <Space direction="horizontal" size={4}>
          <If condition={user?.type === UserType.Admin}>
            <button
              className="button-circle"
              onClick={() =>
                confirmDangerousAction({
                  action: 'clear',
                  actionText: 'Yes',
                  cancelText: 'No',
                  name: 'this chat',
                  dangerColor: true,
                  onConfirm: () => clearChat(id),
                })
              }
              style={{ marginRight: '10px' }}
            >
              <ClearOutlined className="arrow" style={{ fontSize: '16px' }} />
            </button>
          </If>
        </Space>
      </div>
    </Header>
  );
};

export default ChatHeader;
