import { AxiosRequestConfig } from 'axios';

export const CreditsCheckoutRequest = (data: {
  characterId?: string;
  priceId: string;
  route: string;
}): AxiosRequestConfig => {
  return {
    url: 'v1/billing/credits/checkout',
    method: 'post',
    data,
  };
};
