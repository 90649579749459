import { Space } from 'antd';

import SubscriptionBenefit from './subscription-benefit.component';

const SubscriptionBenefits: React.FC = () => {
  return (
    <Space direction="vertical" style={{ marginBottom: '32px' }}>
      <div
        style={{
          fontSize: '20px',
          color: 'var(--grey6)',
          marginBottom: '20px',
        }}
      >
        Premium Benefits
      </div>

      <Space direction="vertical" size="middle">
        <SubscriptionBenefit label="Unlimited text messages" />
        <SubscriptionBenefit label="Get 100 FREE credits / month" />
        <SubscriptionBenefit label="Pictures" />
        <SubscriptionBenefit label="Videos" />
        <SubscriptionBenefit label="Listen to voice messages" />
        <SubscriptionBenefit label="Send voice messages" />
        <SubscriptionBenefit label="Fast response time" />
      </Space>
    </Space>
  );
};

export default SubscriptionBenefits;
