import { FC, useEffect } from 'react';
import { Avatar, Layout, Space } from 'antd';
import Icon from '@ant-design/icons';

import {
  ChatSvg,
  If,
  NavigationButton,
  ProfileSvg,
  SubscriptionSvg,
  useLayoutDisplay,
  FlameSvg,
  ExploreSvg,
} from '../../common';
import { useAuthStore, useInitStore } from '../../stores';
import { RouteData } from '../../utils';

const { Footer } = Layout;

const AppFooter: FC<{
  routeData: RouteData;
  maxWidth?: string;
  setVisible: (value: boolean) => void;
}> = ({ setVisible }) => {
  const user = useInitStore((state) => state.user);
  const token = useAuthStore((state) => state.token);

  const { isMobile } = useLayoutDisplay();

  const isVisible = !!token && isMobile;

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  if (!isVisible) return <></>;

  return (
    <Footer>
      <Space direction="horizontal" size={24}>
        <NavigationButton to="/" active={location.pathname === '/'} large>
          <Icon component={ExploreSvg} style={{ width: '26px' }} />
        </NavigationButton>

        <NavigationButton
          to="/messages"
          active={location.pathname === '/messages'}
          large
        >
          <Icon component={ChatSvg} style={{ width: '26px' }} />
        </NavigationButton>

        <If condition={user?.subscription.active || (user?.credits || 0) > 0}>
          <NavigationButton
            to="/credits"
            active={location.pathname === '/credits'}
            large
          >
            <Icon component={FlameSvg} style={{ width: '26px' }} />
          </NavigationButton>
        </If>

        <If condition={!user?.subscription.active}>
          <NavigationButton
            to="/subscription"
            active={location.pathname === '/subscription'}
            large
          >
            <Icon component={SubscriptionSvg} style={{ width: '26px' }} />
          </NavigationButton>
        </If>

        <NavigationButton
          to="/account"
          active={location.pathname === '/account'}
          large
        >
          <If condition={user?.avatarUrl}>
            <Avatar src={user?.avatarUrl} size={48} />
          </If>

          <If condition={!user?.avatarUrl}>
            <Icon component={ProfileSvg} style={{ width: '26px' }} />
          </If>
        </NavigationButton>
      </Space>
    </Footer>
  );
};

export default AppFooter;
