import { useState } from 'react';
import { Space } from 'antd';

import {
  useAPIRequest,
  SubscriptionPlan,
  SubscriptionCheckoutRequest,
  SubscriptionPlansRequest,
  SubscriptionBillingPeriod,
} from '../../../api';
import { If, Loader } from '../../../common';
import { capitalizeFirstLetter } from '../../../utils';
import SubscriptionBenefits from './subscription-benefits.component';

import './plans.component.css';

const Plans: React.FC = () => {
  const [selected, setSelected] = useState<SubscriptionPlan>();

  const { response: plans, isLoading: isLoadingPlans } = useAPIRequest<{
    data: SubscriptionPlan[];
  }>(SubscriptionPlansRequest, {
    onSuccess: (response) => {
      setSelected(response.data[response.data.length - 1]);
    },
  });

  const { request } = useAPIRequest<{
    url: string;
  }>(SubscriptionCheckoutRequest, {
    immediate: false,
    onSuccess: ({ url }) => {
      if (url) window.location.href = url;
    },
  });

  const confirm = () => {
    if (!selected) return;

    request({ priceId: selected?.id, route: location.pathname });
  };

  return (
    <Loader isLoading={isLoadingPlans}>
      <div className="plans-outer-container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '40px',
          }}
        >
          <div
            style={{
              fontSize: '32px',
              fontWeight: 'bold',
              color: 'var(--white)',
              marginBottom: '5px',
            }}
          >
            Choose your Plan
          </div>

          <div style={{ color: 'var(--grey6)' }}>
            100% anonymous. You can cancel anytime.
          </div>
        </div>

        <Space
          direction="horizontal"
          align="center"
          size={40}
          className="plans-inner-container"
          wrap
        >
          <div className="plans-container-wrapper">
            <Space
              direction="vertical"
              align="center"
              size="middle"
              className="plans-container"
            >
              {plans?.data.map((plan: SubscriptionPlan) => {
                return (
                  <div
                    className={
                      'plan' + (plan.id === selected?.id ? ' active' : '')
                    }
                    key={plan.id}
                    onClick={() => setSelected(plan)}
                  >
                    <div
                      style={{ color: 'var(--orange3)', marginBottom: '20px' }}
                    >
                      {capitalizeFirstLetter(plan.billingPeriod)}
                    </div>

                    <div className="price" style={{ marginBottom: '20px' }}>
                      <div style={{ fontSize: '32px', marginRight: '5px' }}>
                        ${plan.monthlyPrice}
                      </div>

                      <div
                        style={{
                          fontSize: '20px',
                          color: 'var(--grey6)',
                          textDecoration: 'line-through',
                          marginRight: '5px',
                        }}
                      >
                        ${plan.originalMonthlyPrice}
                      </div>

                      <div style={{ fontSize: '14px', textWrap: 'nowrap' }}>
                        / month
                      </div>
                    </div>

                    <div
                      style={{
                        fontSize: '14px',
                        color: 'var(--grey5)',
                        textWrap: 'nowrap',
                      }}
                    >
                      Cancel anytime, privacy in bank statement
                    </div>
                  </div>
                );
              })}
            </Space>

            <If condition={!isLoadingPlans}>
              <button
                className={'confirm-button' + (selected ? ' active' : '')}
                onClick={confirm}
                disabled={!selected}
              >
                Buy Plan
              </button>

              <div
                style={{
                  fontSize: '14px',
                  color: 'var(--grey6)',
                  marginTop: '10px',
                  visibility:
                    selected?.billingPeriod ===
                    SubscriptionBillingPeriod.Annually
                      ? 'visible'
                      : 'hidden',
                }}
              >
                Annual payment billed as ${selected?.price}
              </div>
            </If>
          </div>

          <SubscriptionBenefits />
        </Space>
      </div>
    </Loader>
  );
};

export default Plans;
