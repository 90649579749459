export const BackSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 3L4.11539 8.7008C4.07911 8.73833 4.05007 8.7844 4.03018 8.83598C4.01028 8.88755 4 8.94345 4 9C4 9.05655 4.01028 9.11245 4.03018 9.16402C4.05007 9.2156 4.07911 9.26167 4.11539 9.2992L10 15"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MenuSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 7H19M5 12H19M5 17H19"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
