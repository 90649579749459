import { AppLayout } from '../../../layouts/app/app-layout.component';
import { RouteData } from '../../../utils';
import Subscription from './subscription.component';

export const SubscriptionRoutes: RouteData[] = [
  {
    path: 'subscription',
    title: 'Subscription',
    component: Subscription,
    authRequired: true,
    layout: AppLayout,
  },
];
