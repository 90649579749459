import { AxiosRequestConfig } from 'axios';

import { ChangePasswordParams } from './change-password-params.interface';

export const AcceptInvitationRequest = (
  params: ChangePasswordParams,
): AxiosRequestConfig => {
  return {
    url: 'v1/invitation/accept',
    method: 'post',
    data: params,
  };
};
