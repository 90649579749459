import { useInitStore } from '../../../stores';
import Plans from './plans.component';
import ActivePlan from './active-plan.component';

const Subscription: React.FC = () => {
  const user = useInitStore((state) => state.user);

  return user?.subscription.active ? <ActivePlan /> : <Plans />;
};

export default Subscription;
