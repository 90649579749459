import Icon, { WarningOutlined } from '@ant-design/icons';

import { useInitStore } from '../../../stores';
import { ClassicModal, CloseSvg, WideButton } from '../../../common';

import './cancel-subscription-modal.component.css';

const CancelSubscriptionModal: React.FC<{
  isOpen?: boolean;
  close: () => void;
  confirm: () => void;
}> = ({ isOpen, close, confirm }) => {
  const user = useInitStore((state) => state.user);

  return (
    <ClassicModal isOpen={!!isOpen} close={close} width="80%" maxWidth="400px">
      <div className="button-circle" onClick={close}>
        <Icon component={CloseSvg} />
      </div>

      <div className="cancel-subscription-modal-container">
        <WarningOutlined
          style={{
            fontSize: '100px',
            color: 'var(--orange2)',
            marginBottom: '30px',
          }}
        />

        <div
          style={{
            textAlign: 'center',
            fontSize: '28px',
            fontWeight: 'bold',
            color: 'var(--white)',
            marginBottom: '10px',
          }}
        >
          Warning!
          <br />
          You'll lose all your content
        </div>

        <div
          style={{
            textAlign: 'center',
            marginBottom: '50px',
            color: 'var(--grey6)',
          }}
        >
          By unsubscribing, you'll lose access to your {user?.credits} credits,
          and all your chats at the end of the billing period. Are you sure?
        </div>

        <WideButton
          label="Keep Subscription"
          accent
          onClick={close}
          style={{ marginBottom: '10px' }}
        />

        <WideButton
          label="Cancel Subscription"
          onClick={() => {
            confirm();
            close();
          }}
        />
      </div>
    </ClassicModal>
  );
};

export default CancelSubscriptionModal;
