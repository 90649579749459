import { FC } from 'react';

import { TextType } from './text-type.enum';

import './message-text-items.component.css';

const MessageTextItems: FC<{ items?: { text: string; type: TextType }[] }> = ({
  items,
}) => {
  return (
    <div>
      {items?.map((item) => {
        return (
          <span
            className={item.type === TextType.Stars ? 'text-stars' : undefined}
            key={item.text}
          >
            {item.text}
          </span>
        );
      })}
    </div>
  );
};

export default MessageTextItems;
