import Icon from '@ant-design/icons';

import { Features, StarSvg, TitleIcon, WideButton } from '../../../common';
import { useInitStore, useMiscStore } from '../../../stores';

import './credits.component.css';

const Credits: React.FC = () => {
  const user = useInitStore((state) => state.user);

  const { setCreditsDrawerOpen } = useMiscStore();

  return (
    <div className="credits-page-container">
      <div className="credits-container">
        <div className="balance-container">
          <div className="credits-value">
            <img
              src="/flame.png"
              style={{ width: '24px', marginRight: '10px' }}
            />{' '}
            {user?.credits}
          </div>

          <WideButton
            label="Top Up"
            accent
            onClick={() => setCreditsDrawerOpen(true)}
          />
        </div>
      </div>

      <div className="credits-note" style={{ marginTop: '1px' }}>
        Powered by Stripe
      </div>

      <div style={{ marginTop: '40px' }}>
        <TitleIcon
          label="Credit Benefits"
          style={{
            paddingLeft: '16px',
            marginBottom: '18px',
            color: 'var(--orange4)',
          }}
        >
          <Icon component={StarSvg} style={{ width: '22px' }} />
        </TitleIcon>

        <Features />
      </div>
    </div>
  );
};

export default Credits;
