import { Howl } from 'howler';
import { useRef, useState } from 'react';

export const useSingleAudio = () => {
  const [currentUrl, setCurrentUrl] = useState<string>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [hookActivated, setHookActivated] = useState<boolean>(false);

  const soundRef = useRef<Howl>();

  const createNewSound = (url: string) => {
    soundRef.current?.stop();

    let format = undefined;

    // recorded speech in base64, howler can't detect its format automatically
    if (url.startsWith('data:')) {
      const match = url.match(`(audio|video)\/(.*?);.*`);

      if (match) {
        format = match[2];

        if (format === 'mpeg' || format === 'mp4') format = 'mp3';
      }
    }

    const newSound = new Howl({
      src: url,
      autoplay: true,
      format,
      onend: () => {
        setCurrentUrl(undefined);

        soundRef.current = undefined;
      },
    });

    setCurrentUrl(url);

    soundRef.current = newSound;
  };

  const play = (url: string) => {
    if (url === currentUrl) soundRef.current?.play();
    else createNewSound(url);

    setIsPlaying(true);
    setHookActivated(true);
  };

  const pause = () => {
    soundRef.current?.pause();

    setIsPlaying(false);
  };

  const playPause = (url: string) => {
    if (url === currentUrl && soundRef.current?.playing()) pause();
    else play(url);
  };

  return {
    play,
    pause,
    playPause,
    url: isPlaying ? currentUrl : undefined,
    hookActivated,
  };
};
