import { Space } from 'antd';
import { FC } from 'react';

import { If } from '../if.component';

import './credits-drawer-title.component.css';

const CreditsDrawerTitle: FC<{
  insufficientCredits: boolean;
  style?: React.CSSProperties;
}> = ({ insufficientCredits, style }) => {
  return (
    <Space
      direction="vertical"
      align="center"
      style={{ justifyContent: 'center', width: '100%', ...style }}
      size={24}
    >
      <label className="credits-drawer-title">
        {insufficientCredits ? `You're out of credits!` : `Add credits`}
      </label>

      <If condition={insufficientCredits}>
        <label className="credits-drawer-subtitle">
          top up credits to continue talking
        </label>
      </If>
    </Space>
  );
};

export default CreditsDrawerTitle;
