import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Select } from 'antd';

import { CenteredContainer, SelectAutoClose } from '../../../common';
import { useInitStore, usePersistedStore } from '../../../stores';
import {
  useAPIRequest,
  formRequestErrorHandler,
  UserParams,
  UpdateUserRequest,
  Gender,
} from '../../../api';

import '../../../common/components/auth-drawer/auth-form.component.css';

const { Option } = Select;

const Onboarding: React.FC = () => {
  const navigate = useNavigate();

  const defaultPath = usePersistedStore((state) => state.defaultPath);
  const { user, setUser } = useInitStore();

  const [form] = Form.useForm();

  const { request, isLoading } = useAPIRequest<UserParams>(UpdateUserRequest, {
    immediate: false,
    onSuccess: (response) => {
      setUser(response);

      if (defaultPath) navigate(defaultPath);
    },
    onError: formRequestErrorHandler(form),
  });

  return (
    <CenteredContainer className="auth-form" vertical style={{ width: '100%' }}>
      <div className="title" style={{ marginBottom: '24px' }}>
        Personalize Your AI Experience
      </div>

      <Form
        form={form}
        onFinish={request}
        initialValues={{
          name: user?.name,
          gender: user?.gender || Gender.Male,
        }}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        disabled={isLoading}
        style={{ width: '90%', maxWidth: '400px' }}
      >
        <div className="description" style={{ marginBottom: '24px' }}>
          It will help to customize your interactions with the AI characters
        </div>

        <Form.Item
          name="name"
          rules={[
            { required: true, message: 'Name is required' },
            {
              max: 30,
              message: 'Name must be maximum 30 characters',
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          name="gender"
          rules={[{ required: true, message: 'Gender is required' }]}
        >
          <SelectAutoClose placeholder="Select gender">
            <Option value={Gender.Male}>Male</Option>
            <Option value={Gender.Female}>Female</Option>
          </SelectAutoClose>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="light"
          >
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </CenteredContainer>
  );
};

export default Onboarding;
