import { RouteData } from '../../utils';

import Invitation from './invitation/invitation.component';
import ChangePassword from './change-password/change-password.component';
import { AuthLayout } from '../../layouts/auth-layout/auth-layout.component';

export const AuthRoutes: RouteData[] = [
  {
    path: 'change-password',
    component: ChangePassword,
    isAuthRoute: true,
    layout: AuthLayout,
  },
  {
    path: 'invitation',
    component: Invitation,
    isAuthRoute: true,
    layout: AuthLayout,
  },
];
