import axios, { AxiosRequestConfig } from 'axios';

import { APIRequestConfig, RequestError } from './interfaces';
import { authStore } from '../stores';
import { promiseLifecycle } from '../common';

export function apiRequest<T = any>(config: AxiosRequestConfig) {
  return new Promise<T>(async (resolve, reject) => {
    let token: string | undefined;

    try {
      token = authStore.getState().token;

      if (token) {
        if (!config.headers) config.headers = {};

        config.headers['Authorization'] = 'Bearer ' + token;
      }

      config.baseURL = process.env.REACT_APP_API;
      // config.withCredentials = true;

      const result = await axios.request(config);

      resolve(result.data);
    } catch (e: any) {
      console.error(e);

      let errorResponse;

      if (e.response?.data) {
        const data: RequestError = e.response.data;

        if (typeof data === 'string') {
          errorResponse = {
            error: e.response.statusText,
            message: e.response.statusText,
            statusCode: e.response.status,
          };
        } else {
          const message: string = Array.isArray(data.message)
            ? data.message.join(', ')
            : data.message;

          // sign out if unauthorized
          if (e.response.status === 401 && token) {
            authStore.getState().clearToken();
          }

          errorResponse = { ...data, message };
        }
      } else {
        errorResponse = {
          error: 'No response',
          message: 'No response',
          statusCode: 404,
        };
      }

      reject(errorResponse);
    }
  });
}

export async function apiRequestLifecycle<T = any>({
  config,
  onStart,
  onComplete,
  onSuccess,
  onError,
}: APIRequestConfig<T>) {
  try {
    return await promiseLifecycle({
      factory: () => apiRequest(config),
      onStart,
      onComplete,
      onSuccess,
      onError,
    });
  } catch (e) {
    // do nothing. makes sure to not throw error, the callback in promiseLifecycle is enough
  }
}
