import { useMemo, useRef } from 'react';

import { MediaType, Message } from '../../../../../api';
import { MessageListItem } from './message-list-item.interface';
import { ParsedMessagePosition } from './parsed-message-position.enum';
import { ParsedMessageType } from './parsed-message-type.enum';
import { formatShortDate, isToday } from '../../../../../utils';

export const useParsedMessages = ({
  messages,
  onChange,
}: {
  messages?: Message[];
  onChange?: () => void;
}) => {
  const previousParsedMessagesLength = useRef<number>(0);
  const previousLastMessageResponse = useRef<string>();

  return useMemo(() => {
    if (!messages) return [];

    let lastDate: string | undefined = undefined;
    let todayOnly = true;

    const newParsedMessages = messages.flatMap((message) => {
      const result: MessageListItem[] = [];

      let dateItems = 0;

      const messageDate = isToday(message.sent)
        ? 'Today'
        : formatShortDate(message.sent);

      if (lastDate != messageDate) {
        result.push({ date: messageDate });

        // makes sure the first message item (excluding date item) has messageItemIndex 0
        // it's used to scroll to the last top message after loading more paginated messages
        dateItems = 1;
      }

      lastDate = messageDate;

      if (messageDate !== 'Today') todayOnly = false;

      if (message.message || message.messageVoiceUrl) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-message-text',
            messageItemIndex: result.length - dateItems,
            position: ParsedMessagePosition.Right,
            type: ParsedMessageType.Text,
            value: message.message,
            voiceUrl: message.messageVoiceUrl,
            sent: message.sent,
          },
        });
      }

      if (message.media) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-' + message.media.type,
            messageItemIndex: result.length - dateItems,
            position: ParsedMessagePosition.Left,
            type:
              message.media.type === MediaType.Picture
                ? ParsedMessageType.Picture
                : ParsedMessageType.Video,
            sent: message.sent,
            media: message.media,
          },
        });
      }

      if (message.response || message.responseVoiceUrl) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-response-text',
            messageItemIndex: result.length - dateItems,
            position: ParsedMessagePosition.Left,
            type: ParsedMessageType.Text,
            value: message.response,
            voiceUrl: message.responseVoiceUrl,
            sent: message.sent,
            voiceLoading: message.responseVoiceLoading,
          },
        });
      } else if (!message.media) {
        result.push({
          message: {
            id: message.id!,
            key: message.id + '-response-loader',
            messageItemIndex: result.length - dateItems,
            position: ParsedMessagePosition.Left,
            type: ParsedMessageType.Text,
            value: message.response,
            sent: message.sent,
            loading: true,
          },
        });
      }

      return result;
    });

    if (todayOnly) {
      const todayIndex = newParsedMessages.findIndex((value) => value.date);

      newParsedMessages.splice(todayIndex, 1);
    }

    const lastMessageResponse =
      newParsedMessages[newParsedMessages.length - 1]?.message?.value;

    if (
      previousParsedMessagesLength.current !== newParsedMessages.length ||
      previousLastMessageResponse.current !== lastMessageResponse
    ) {
      onChange?.();
    }

    previousParsedMessagesLength.current = newParsedMessages.length;
    previousLastMessageResponse.current = lastMessageResponse;

    return newParsedMessages;
  }, [messages]);
};
