import { RouteData } from '../../utils';
import { LegalRoutes } from './legal/legal.routes';
import { CharacterRoutes } from './character/character.routes';
import { BrowseRoutes } from './browse/browse.routes';
import { CreditsRoutes } from './credits/credits.routes';
import { AccountRoutes } from './account/account.routes';
import { MessagesRoutes } from './messages/messages.routes';
import { IndexRoutes } from './index/index.routes';
import { SubscriptionRoutes } from './subscription/subscription.routes';

export const AppRoutes: RouteData[] = [
  ...IndexRoutes,
  ...LegalRoutes,
  ...BrowseRoutes,
  ...SubscriptionRoutes,
  ...CreditsRoutes,
  ...AccountRoutes,
  ...MessagesRoutes,
  ...CharacterRoutes, // must be last, route can only be a username if it's not any of the routes above
];
