import { Buffer } from 'buffer';
import { useRef, useState } from 'react';

import { PermissionStatus } from './permission-status.enum';
import { AuthDrawerState } from '../../../../common';
import { useAuthStore, useInitStore, useMiscStore } from '../../../../stores';

const getSupportedMimeTypes = (media: any, types: any, codecs: any) => {
  const isSupported = MediaRecorder.isTypeSupported;
  const supported: any = [];

  types.forEach((type: any) => {
    const mimeType = `${media}/${type}`;

    codecs.forEach((codec: any) =>
      [
        `${mimeType};codecs=${codec}`,
        `${mimeType};codecs=${codec.toUpperCase()}`,
      ].forEach((variation) => {
        if (isSupported(variation)) supported.push(variation);
      }),
    );

    if (isSupported(mimeType)) supported.push(mimeType);
  });

  return supported;
};

const getSupportedMimeType = (): string => {
  const videoTypes = ['webm', 'ogg', 'mp4', 'x-matroska'];
  const audioTypes = ['webm', 'ogg', 'mp3', 'x-matroska'];

  const codecs = [
    'should-not-be-supported',
    'vp9',
    'vp9.0',
    'vp8',
    'vp8.0',
    'avc1',
    'av1',
    'h265',
    'h.265',
    'h264',
    'h.264',
    'opus',
    'pcm',
    'aac',
    'mpeg',
    'mp4a',
  ];

  const supportedVideos = getSupportedMimeTypes('video', videoTypes, codecs);
  const supportedAudios = getSupportedMimeTypes('audio', audioTypes, codecs);

  return supportedAudios[0] || supportedVideos[0];
};

const mimeType = getSupportedMimeType();

const MAX_SECONDS = 30;

export const useSpeechRecorder = (
  onComplete?: (base64: string, mimeType: string) => any,
) => {
  const stream = useRef<MediaStream>();
  const recorder = useRef<MediaRecorder>();
  const [recording, setRecording] = useState<boolean>(false);

  const [requestingMicPermission, setRequestingMicPermission] =
    useState<boolean>(false);

  const [micPermissionStatus, setMicPermissionStatus] =
    useState<PermissionStatus>();

  let timeoutId: any = null;

  const user = useInitStore((state) => state.user);
  const token = useAuthStore((state) => state.token);

  const { setSubscriptionModalOpen, setAuthOpen } = useMiscStore();

  const lastStopRecording = useRef<() => void>();

  const toggleRecording = async () => {
    if (!token) setAuthOpen(true, AuthDrawerState.SignUp);
    else {
      if (user?.subscription.active) {
        if (recording) {
          stopRecording();
        } else return await startRecording();
      } else setSubscriptionModalOpen(true);
    }
  };

  const startRecording = async (): Promise<void> => {
    if (recording) return;

    setRequestingMicPermission(true);

    const newPermissionStatus: PermissionStatus =
      await requestMicrophonePermission();

    setMicPermissionStatus(newPermissionStatus);
    setRequestingMicPermission(false);

    if (newPermissionStatus !== PermissionStatus.Success) return;

    setRecording(true);

    try {
      recorder.current = new MediaRecorder(stream.current!, { mimeType });
    } catch (e: any) {
      // this.emit(SmartNPCSpeechRecognitionEvent.Exception, e); // TODO

      return;
    }

    const audioChunks: any[] = [];

    recorder.current.addEventListener('dataavailable', (event) => {
      audioChunks.push(event.data);
    });

    recorder.current.addEventListener('stop', async () => {
      const blob = new Blob(audioChunks);

      const byteArray: ArrayBuffer = await blob.arrayBuffer();

      const base64: string = Buffer.from(byteArray).toString('base64');

      onComplete?.(base64, mimeType);

      if (timeoutId) {
        clearTimeout(timeoutId);

        timeoutId = null;
      }
    });

    recorder.current.start();

    timeoutId = setTimeout(() => {
      lastStopRecording.current?.();

      timeoutId = null;
    }, MAX_SECONDS * 1000);
  };

  const stopRecording = (): void => {
    if (!recording) return;

    try {
      recorder.current?.stop();

      stream.current?.getAudioTracks().forEach((value) => value.stop());
    } catch (e) {
      console.error(e);
    }

    setRecording(false);

    stream.current = undefined;
    recorder.current = undefined;

    if (timeoutId) {
      clearTimeout(timeoutId);

      timeoutId = null;
    }
  };

  const requestMicrophonePermission = async (): Promise<PermissionStatus> => {
    try {
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
    } catch (e: any) {
      if (e.message === 'Permission dismissed') {
        return PermissionStatus.Dismissed;
      }

      return PermissionStatus.Blocked;
    }

    return PermissionStatus.Success;
  };

  const getMicrophonePermission = async (): Promise<PermissionStatus> => {
    const result = await navigator.permissions.query({
      name: 'microphone',
    } as any);

    switch (result.state) {
      case 'denied':
        return PermissionStatus.Blocked;
      case 'prompt':
        return PermissionStatus.Dismissed;
      case 'granted':
        return PermissionStatus.Success;
    }
  };

  lastStopRecording.current = stopRecording;

  return {
    startRecording,
    stopRecording,
    toggleRecording,
    getMicrophonePermission,
    recording,
    requestingMicPermission,
    micPermissionStatus,
  };
};
