import { Avatar, Layout, Space } from 'antd';
import Icon from '@ant-design/icons';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ChatSvg,
  DiscordSvg,
  If,
  MoreSvg,
  NavigationButton,
  ProfileSvg,
  WideButton,
  useLayoutDisplay,
  SubscriptionSvg,
  FlameSvg,
  ExploreSvg,
} from '../../common';
import { useInitStore, useMiscStore } from '../../stores';
import { RouteData } from '../../utils';

import './app-sider.component.css';

const { Sider } = Layout;

export const AppSider: FC<{ routeData: RouteData }> = () => {
  const location = useLocation();

  const { displaySider, siderWidth } = useLayoutDisplay();

  const user = useInitStore((state) => state.user);
  const setMenuOpen = useMiscStore((state) => state.setMenuOpen);

  if (!displaySider) return <></>;

  return (
    <>
      <div style={{ width: siderWidth }} />

      <Sider trigger={null} width={siderWidth}>
        <div className="app-sider-content">
          <div style={{ padding: '2px 14px 0px 14px' }}>
            <div style={{ width: '100%' }}>
              <Space
                direction="horizontal"
                size={0}
                style={{ marginBottom: '30px' }}
              >
                <img src="/logoicon.png" />

                <div style={{ marginLeft: '10px', userSelect: 'none' }}>
                  AnimeGF
                </div>
              </Space>
            </div>

            <Space direction="vertical" size={12}>
              <NavigationButton
                label="Explore"
                to="/"
                active={location.pathname === '/'}
              >
                <Icon component={ExploreSvg} style={{ width: '16px' }} />
              </NavigationButton>

              <NavigationButton
                label="Messages"
                to="/messages"
                active={location.pathname === '/messages'}
              >
                <Icon component={ChatSvg} style={{ width: '16px' }} />
              </NavigationButton>

              <If
                condition={
                  user?.subscription.active || (user?.credits || 0) > 0
                }
              >
                <NavigationButton
                  label="Credits"
                  to="/credits"
                  active={location.pathname === '/credits'}
                >
                  <Icon component={FlameSvg} style={{ width: '16px' }} />
                </NavigationButton>
              </If>

              <NavigationButton
                label="Subscription"
                to="/subscription"
                active={location.pathname === '/subscription'}
              >
                <Icon component={SubscriptionSvg} style={{ width: '16px' }} />
              </NavigationButton>

              <NavigationButton
                label="Account"
                to="/account"
                active={location.pathname === '/account'}
              >
                <If condition={user?.avatarUrl}>
                  <Avatar src={user?.avatarUrl} size={32} />
                </If>

                <If condition={!user?.avatarUrl}>
                  <Icon component={ProfileSvg} style={{ width: '16px' }} />
                </If>
              </NavigationButton>

              <NavigationButton label="More" onClick={() => setMenuOpen(true)}>
                <Icon component={MoreSvg} style={{ width: '16px' }} />
              </NavigationButton>
            </Space>
          </div>

          <WideButton
            label="Discord"
            to={process.env.REACT_APP_DISCORD}
            target="_blank"
          >
            <Icon component={DiscordSvg} style={{ width: '16px' }} />
          </WideButton>
        </div>
      </Sider>
    </>
  );
};
