import { useEffect, useCallback, useRef, useState } from 'react';

import {
  useAPIRequest,
  ChatRequest,
  ChatCharacter,
  FindCharacterRequest,
  SendMessageResponse,
  RequestError,
  requestErrorHandler,
  MessageRequest,
  VoiceMessageRequest,
  WelcomeMessageRequest,
  UnlockMessageMediaRequest,
  ChatParams,
  MessagesRequest,
  MessagesParams,
  GenerateVoiceRequest,
  Message,
} from '../../api';
import {
  useInitStore,
  useAuthStore,
  useChatStore,
  useMiscStore,
} from '../../stores';
import { AuthDrawerState } from '../components';
import { useSingleAudio } from './use-single-audio.hook';

const PER_PAGE = 50;

export const useChat = (handle?: string, load = false) => {
  const {
    reset: resetState,
    id,
    setId,
    firstPageLoaded,
    setFirstPageLoaded,
    paginationToken,
    setPaginationToken,
    messages,
    setMessages,
    character,
    setCharacter,
    viewedMedia,
    setViewedMedia,
    sendingMessage,
    setSendingMessage,
    sendWelcomeMessageFlag,
    setSendWelcomeMessageFlag,
    voicePlaying,
    setVoicePlaying,
    loadingMessages,
    setLoadingMessages,
  } = useChatStore();

  const { setCreditsDrawerOpen, setSubscriptionModalOpen, setAuthOpen } =
    useMiscStore();

  const token = useAuthStore((state) => state.token);
  const setCredits = useInitStore((state) => state.setCredits);

  const requestedCharacter = useRef<boolean>(false);
  const hasToken = useRef<boolean>(!!token);
  const chatLoaded = useRef<boolean>(false);
  const sendingWelcomeMessageOnLoad = useRef<boolean>(false);
  const sendingGuestWelcomeMessage = useRef<boolean>(false);
  const messagesToUpdate = useRef<Message[]>([]);

  const [forcedMessagesUpdate, setForcedMessagesUpdate] = useState<number>(0);

  const reset = () => {
    chatLoaded.current = false;
    requestedCharacter.current = false;

    resetState();
  };

  const updateMessage = (message: Message) => {
    messagesToUpdate.current.push(message);

    setForcedMessagesUpdate(Math.random() * 1000000);
  };

  const { request: requestMessages, isLoading: isLoadingMessages } =
    useAPIRequest<MessagesParams>(
      () => MessagesRequest(id!, PER_PAGE, paginationToken),
      {
        immediate: false,
        onStart: () => setLoadingMessages(true),
        onSuccess: (result) => {
          setMessages([...result.messages, ...messages]);
          setLoadingMessages(false);
          setFirstPageLoaded(true);
          setPaginationToken(result.paginationToken);
        },
      },
    );

  const loadMoreMessages = useCallback(() => {
    if (!isLoadingMessages && id && (!firstPageLoaded || paginationToken)) {
      requestMessages();

      return messages?.[0]?.id; // returns top message before more message are loaded
    }
  }, [isLoadingMessages, id, firstPageLoaded, paginationToken]);

  const { request: requestChat, isLoading: isLoadingChat } =
    useAPIRequest<ChatParams>(ChatRequest, {
      immediate: false,
      onSuccess: (chat) => {
        setId(chat.id);

        chatLoaded.current = true;

        // sendingWelcomeMessageOnLoad ref makes sure it's not called twice due to how useEffect works in dev
        if (
          chat.character.hasWelcomeMessage &&
          chat.totalMessages === 0 &&
          !sendingWelcomeMessageOnLoad.current
        ) {
          sendingWelcomeMessageOnLoad.current = true;

          setTimeout(() => setSendWelcomeMessageFlag(true), 1000);
        }
      },
    });

  const { request: requestCharacter, isLoading: isLoadingCharacter } =
    useAPIRequest<ChatCharacter>(() => FindCharacterRequest(handle!), {
      immediate: false,
      onStart: reset,
      onSuccess: (response) => {
        setCharacter(response);

        if (token) requestChat(response.id);
        else {
          // sendingWelcomeMessageOnLoad ref makes sure it's not called twice due to how useEffect works in dev
          if (
            response.guestWelcomeMessage &&
            !sendingWelcomeMessageOnLoad.current
          ) {
            sendingWelcomeMessageOnLoad.current = true;

            setTimeout(() => setSendWelcomeMessageFlag(true), 1000);
          }
        }
      },
    });

  const sendMessageOptions = {
    immediate: false,
    onSuccess: ({ message, credits }: SendMessageResponse) => {
      setMessages(messages.concat([message]));
      setCredits(credits);
    },
    onError: (error: RequestError) => {
      setMessages(messages.concat());

      if (error.message === 'Insufficient credits') {
        setCreditsDrawerOpen(true, true);
      } else if (error.message === 'Subscription required') {
        setSubscriptionModalOpen(true);
      } else requestErrorHandler()(error);
    },
  };

  const { request: sendMessageRequest, isLoading: isSendingMessage } =
    useAPIRequest<SendMessageResponse>(
      (text: string) => MessageRequest({ id: id!, text }),
      sendMessageOptions,
    );

  const { request: sendVoiceMessageRequest, isLoading: isSendingVoiceMessage } =
    useAPIRequest<SendMessageResponse>(VoiceMessageRequest, sendMessageOptions);

  const {
    request: sendWelcomeMessageRequest,
    isLoading: isSendingWelcomeMessage,
  } = useAPIRequest<SendMessageResponse>(
    () => WelcomeMessageRequest(id!),
    sendMessageOptions,
  );

  const { playPause, url: urlPlaying, hookActivated } = useSingleAudio();

  useEffect(() => {
    setVoicePlaying(urlPlaying);
  }, [hookActivated, urlPlaying]);

  // TODO: make sure this works for multiple concurrent requests
  const { request: generateVoice } = useAPIRequest<SendMessageResponse>(
    GenerateVoiceRequest,
    {
      ...sendMessageOptions,
      onSuccess: ({ message, credits }: SendMessageResponse) => {
        updateMessage(message);
        playPause(message.responseVoiceUrl!);
        setCredits(credits);
      },
    },
  );

  const playPauseVoice = (
    chatId: string,
    messageId: string,
    voiceUrl?: string,
  ) => {
    if (voiceUrl) playPause(voiceUrl);
    else {
      const messageIndex = messages.findIndex(
        (value) => value.id === messageId,
      );

      if (messageIndex !== -1) {
        const message = messages[messageIndex];

        updateMessage({ ...message, responseVoiceLoading: true });
        generateVoice(chatId, messageId);
      }
    }
  };

  const { request: unlockMessageMedia, isLoading: isUnlockingMessageMedia } =
    useAPIRequest<SendMessageResponse>(UnlockMessageMediaRequest, {
      ...sendMessageOptions,
      onSuccess: ({ message, credits }: SendMessageResponse) => {
        updateMessage(message);
        setViewedMedia(message.media);
        setCredits(credits);
      },
    });

  const sendMessage = (text: string) => {
    if (token) {
      setMessages(messages.concat([{ message: text, sent: new Date() }]));
      sendMessageRequest(text);
    } else {
      setAuthOpen(true, AuthDrawerState.SignUp);
    }
  };

  const base64ToFile = async (base64: string, mimeType: string) => {
    const response = await fetch('data:' + mimeType + ';base64,' + base64);

    return await response.blob();
  };

  const sendVoiceMessage = async (base64: string, mimeType: string) => {
    if (token) {
      setMessages(
        messages.concat([
          {
            messageVoiceUrl: `data:${mimeType};base64,${base64}`,
            sent: new Date(),
          },
        ]),
      );

      sendVoiceMessageRequest({
        id: id!,
        file: await base64ToFile(base64, mimeType),
      });
    } else {
      setAuthOpen(true, AuthDrawerState.SignUp);
    }
  };

  const sendWelcomeMessage = () => {
    setMessages(messages.concat([{ sent: new Date() }]));

    if (token) sendWelcomeMessageRequest();
    else {
      sendingGuestWelcomeMessage.current = true;

      setMessages(messages.concat([{ sent: new Date() }]));

      setTimeout(() => {
        sendingGuestWelcomeMessage.current = false;

        setMessages(messages.concat([character!.guestWelcomeMessage!]));
      }, 1000);
    }
  };

  useEffect(() => {
    // if hook is set to load on init, and haven't requested character yet or just signed in / signed out
    if (load && (!requestedCharacter.current || hasToken.current != !!token)) {
      requestCharacter();

      requestedCharacter.current = true;
      hasToken.current = !!token;
    }
  }, [handle, requestedCharacter, load, token]);

  useEffect(() => {
    if (chatLoaded.current && id && load && token) loadMoreMessages();
  }, [chatLoaded, id, load, token]);

  useEffect(() => {
    if (sendWelcomeMessageFlag && load) {
      sendWelcomeMessage();
      setSendWelcomeMessageFlag(false);

      sendingWelcomeMessageOnLoad.current = false;
    }
  }, [sendWelcomeMessage, sendWelcomeMessageFlag, load]);

  const isSendingMessageInner =
    isSendingMessage ||
    isSendingVoiceMessage ||
    isSendingWelcomeMessage ||
    sendingGuestWelcomeMessage.current;

  useEffect(() => {
    setSendingMessage(isSendingMessageInner);
  }, [isSendingMessageInner]);

  useEffect(() => {
    if (messagesToUpdate.current.length > 0) {
      const newMessages = messages.concat();

      for (let i = 0; i < messagesToUpdate.current.length; i++) {
        const message = messagesToUpdate.current[i];

        const messageIndex = messages.findIndex(
          (value) => value.id === message.id!,
        );

        if (messageIndex !== -1) newMessages[messageIndex] = message;
      }

      messagesToUpdate.current = [];

      setMessages(newMessages);
    }
  }, [messages, messagesToUpdate, forcedMessagesUpdate]);

  return {
    id,
    character,
    messages,
    loadMoreMessages,
    isLoadingMessages: loadingMessages,
    sendMessage,
    sendVoiceMessage,
    unlockMessageMedia,
    playPauseVoice,
    isLoading: isLoadingChat || isLoadingCharacter,
    isSendingMessage: sendingMessage || isSendingMessageInner,
    isUnlockingMessageMedia,
    voicePlaying,
    setVoicePlaying,
    viewedMedia,
    setViewedMedia,
  };
};
